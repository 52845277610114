import React, { useState, useEffect } from "react";
import "./App.css";
import tweetImage from "./images/tweettransparent.png";
import waitingGif from "./images/waiting.gif";

const backgroundColors = [
  "#F6958E",
  "RGB(246,149,142)",
  "#F6B48E",
  "RGB(246,180,142)",
  "#F6E38E",
  "RGB(246,227,142)",
  "#BBE9F6",
  "RGB(187,233,246)",
  "#DFB6F6",
  "RGB(223,182,246)",
];

const App = () => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [searchType, setSearchType] = useState("jobs");
  const [showWaitingScreen, setShowWaitingScreen] = useState(false);
  const [fileName, setFileName] = useState("myResume.pdf");
  const [isClicked, setIsClicked] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("");

  useEffect(() => {
    // Select a random background color on component mount
    const randomColor =
      backgroundColors[Math.floor(Math.random() * backgroundColors.length)];
    setBackgroundColor(randomColor);
  }, []);

  // while its loading explain details about how it work and what it can do
  // also have like a snake game to play
  // and a loading animation / technical looking thing

  // also you gotta sign up to see your results

  useEffect(() => {
    if (fileUploaded) {
      setShowWaitingScreen(true);
      const waitingTime = Math.floor(Math.random() * 3000) + 1000; // Random time between 1 and 3 seconds
      setTimeout(() => {
        setShowWaitingScreen(false);
      }, waitingTime);
    }
  }, [fileUploaded]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const truncatedFileName = truncateFileName(file.name);
    setFileName(truncatedFileName);
    // Perform file upload logic here
    // setFileUploaded(true);
  };

  const handleSearchTypeChange = () => {
    setSearchType((prevSearchType) =>
      prevSearchType === "jobs" ? "companies" : "jobs",
    );
  };

  const truncateFileName = (name) => {
    const maxLength = 10; // Adjust the maximum length as needed
    if (name.length > maxLength) {
      return name.slice(0, maxLength) + "...";
    }
    return name;
  };

  const handleButtonClick = () => {
    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
    }, 200); // Adjust the duration of the click animation as needed
    document.getElementById("fileUpload").click();
  };

  if (showWaitingScreen) {
    return (
      <div className="waiting-screen">
        <img src={waitingGif} alt="Waiting" className="waiting-gif" />
      </div>
    );
  }

  if (fileUploaded) {
    return (
      <div className="thank-you-screen">
        <h1>i stole your data</h1>
      </div>
    );
  }

  return (
    <div className="landing-page" style={{ backgroundColor }}>
      <img src={tweetImage} alt="Tweet" className="tweet-image" />
      {/* <div className="subtitle">
        Spearfish finds <s>jobs I want</s> jobs that want me <br />
      </div> */}
      <div className="text-container">
        <p>
          scan{" "}
          <span
            className={`button ${isClicked ? "clicked" : ""}`}
            onClick={handleButtonClick}
          >
            {fileName}
          </span>
          <br />
          and{" "}
          <span
            className="button search-toggle"
            onClick={handleSearchTypeChange}
          >
            {searchType === "jobs"
              ? "3,654 CS internships"
              : "2,542 tech startups"}
          </span>
          <br />
          to{" "}
          <span className="submit-button" onClick={() => setFileUploaded(true)}>
            find who wants me
          </span>
        </p>
      </div>
      <input
        id="fileUpload"
        type="file"
        accept=".pdf"
        onChange={handleFileUpload}
        className="file-input"
      />
    </div>
  );
};

export default App;
